import Vue from 'vue';
import store from '@/store';
import { addMinutes } from 'date-fns';

let $ws = Vue.prototype.$ws;
export default class extends window.Datafeeds.UDFCompatibleDatafeed {
    constructor(datafeedUrl) {
        super(datafeedUrl);
    }

    onReady(callback) {
        setTimeout(function() {
            callback({
                supported_resolutions: ['1', '2', '3','4','5','10', '15', '20','30','45','60','120','240','480','720','1D'],
                supports_group_request: false
            });
        }, 0);
    }

    onPriceUpdate(event) {
        if(store.state.tradingView.subscriptions.hasOwnProperty(event.request.args.symbol)) {
            store.state.tradingView.subscriptions[event.request.args.symbol](event.response.bar);
            // store.state.tradingView.subscriptions[event.request.args.symbol](event.response.nextBar);
        }
    }

    subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
        store.commit('addSubscription', {
            symbol: symbolInfo.ticker,
            callback: onRealtimeCallback
        });

        $ws.on('tradedetailspricesupdate', this.onPriceUpdate);
        $ws.send({
            accountName: store.state.activeAccount,
            request: 'TradeDetailsPricesUpdate',
            args: {
                symbol: symbolInfo.ticker,
                resolution: resolution
            }
        });
    }

    onPrices(event) {
        //lastBar = event.response[event.response.length-1];
        // for(let x of event.response){
        //    x.time = convertToEST(new Date(x.time),6).getTime()
        // }
     
        if(store.state.tradingView.waitingForBars.hasOwnProperty(event.request.args.symbol)) {
            store.state.tradingView.waitingForBars[event.request.args.symbol].onResult(event.response);
        }
    }

    getBars(symbolInfo, resolution, from, to, onResult, onError, firstDataRequest) {
        store.commit('addWaitingForBars', {
            symbol: symbolInfo.ticker,
            onResult: onResult,
            onError: onError
        });

        $ws.once('tradedetailsprices', this.onPrices);
        $ws.send({
            accountName: store.state.activeAccount,
            request: 'TradeDetailsPrices',
            args: {
                symbol: symbolInfo.ticker,
                resolution: resolution,
                first: firstDataRequest,
                startDate: from,
                endDate: to,
                tradeID: store.state.tradingView.selectedTrade.id,
                accountName: store.state.tradingView.selectedTrade.account || store.state.activeAccount,
                firstOnly: store.state.tradingView.selectedTrade.firstOnly
            }
        });
    }

    onSearchSymbols(event) {
        console.log(`Resolved: ${event.request.args.search}`);
        if(store.state.tradingView.waitingForSymbolsSearch.hasOwnProperty(event.request.args.search)) {
            let results = [];
            for(let result of event.response) {
                results.push({
                    symbol: result.Name,
                    full_name: result.Name,
                    description: result.Description,
                    ticker: result.Ticker,
                    type: result.Type
                });
            }

            store.state.tradingView.waitingForSymbolsSearch[event.request.args.search](results);
        }
    }

    searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
        let symbol  = userInput;
        store.commit('addSearchingSymbol', {
            symbol: symbol,
            callback: onResultReadyCallback
        });

        console.log(`Search: ${symbol}`);

        $ws.once('searchsymbols', this.onSearchSymbols);
        $ws.send({
            accountName: store.state.activeAccount,
            request: 'SearchSymbols',
            args: {
                search: symbol
            }
        });
    }

    onResolveSymbol(event) {
        if(event.request.args.chartRequest == "true"){
            store.commit("setFirstOnly", false)
        }else{
            store.commit("setFirstOnly", true)
        }

        if(store.state.tradingView.waitingForSymbolsResolve.hasOwnProperty(event.request.args.symbol)) {
            if(event.response.Name == null){return}

            store.state.tradingView.waitingForSymbolsResolve[event.request.args.symbol].onResolve({
                name: event.response.Name,
                ticker: event.response.Ticker,
                description: event.response.Description,
                type: event.response.Type,
                minmov: event.response.MinMovement1,
                pricescale: event.response.PriceScale,
                session: event.response.Session,
                has_intraday: event.response.HasIntraday,
                has_no_volume: event.response.HasNoVolume,
                timezone: event.response.Timezone,
                has_daily: true,
                has_weekly_and_monthly: true
            });
        } 
    }

    resolveSymbol(symbolName, onResolve, onError) {
        let chartRequest = false
        if(symbolName.includes("CHARTPAGE")){
            chartRequest = true;
            symbolName = symbolName.replace("CHARTPAGE", "")
        }

        store.commit('addResolvingSymbol', {
            symbol: symbolName,
            onResolve: onResolve,
            onError: onError
        });

        $ws.once('resolvesymbol', this.onResolveSymbol);
        $ws.send({
            accountName: store.state.activeAccount,
            request: 'ResolveSymbol',
            args: {
                symbol: symbolName,
                chartRequest: chartRequest
            }
        });
    }
}