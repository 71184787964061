<template>
    <div class="trade-prediction-widget">
        <b-table :items="tableOptions.items" :fields="tableOptions.columns" responsive class="text-center" show-empty>
            <template slot="empty">
                No results found
            </template>
        </b-table>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { map, clone } from 'lodash';
import { parse, format } from 'date-fns';
import TradingViewChart from '@/components/TradingViewChart';
import { exportCsv } from '@/lib/Exporting';
import chart from '@/widgets/highchart';

export default {
    name: 'trade-prediction',
    data() {
        let that = this;
        return{
            tableOptions:{
                items:[],
                columns:[]
            }
        }
    },
    props: {

    },
    components: {

    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('tradeprediction', this.onResponse);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('tradeprediction', this.onResponse);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'TradePrediction',
                args: {}
            });
        },
        onResponse(event) {
            let data = event.response.data
            let totals = event.response.totals
            //columns
            let columns = []
            columns.push({
                key: 'dateTime',
                label: 'Date',
                sortable: true,
                formatter: function(value) {
                    if(Date.parse(value)){
                        return format(new Date(value), "yyyy-MM-dd HH:00:00")
                    }
                    return value
                }
            })
            for(let market of Object.keys(totals)){
                columns.push({
                    key: market,
                    label: market,
                    sortable: true,
                })
            }
            columns.push({
                    key: "total",
                    label: "Total",
                    sortable: true,
            })
            //items
            let items = []
            for(let date of Object.keys(data)){
                let item = {dateTime:date}
                let total = 0;
                for(let market of Object.keys(data[date])){
                    item[market] = data[date][market]
                    total += item[market]
                }
                item.total = total
                items.push(item)
            }
            //totals
            let total = {dateTime:"Total"}
            let totalOfTotals = 0;
            for(let market of Object.keys(totals)){
                total[market] = totals[market]
                totalOfTotals += total[market]

                //add - for empty spaces
                for(let item of items){
                    if(!item.hasOwnProperty(market)){
                        item[market] = "-"
                    }
                }
            }
            total.total = totalOfTotals
            items.push(total)

            this.tableOptions.columns = columns;
            this.tableOptions.items = items;
        },
    }
}
</script>