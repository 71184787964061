<template>
    <div class="tv-chart-container" :id="containerId" :style="`height: ${height};`" />
</template>

<script>
import { widget } from '../charting_library.min';
import DataFeed from '@/lib/DataFeed';
import { addHours, format, addMinutes, addDays, addWeeks, addMonths } from 'date-fns';
import { parseFromTimeZone, formatToTimeZone } from 'date-fns-timezone';

export default {
    name: 'TVChartContainer',
    data() {
        return {
            chart: null,
            entryShapeId: null,
            exitShapeId: null
        }
    },
    props: {
        height: {
            type: String,
            default: '500px'
        },
        symbol: {
            type: String,
            default: 'AAPL'
        },
        interval: {
            type: String,
            default: 'D'
        },
        containerId: {
            type: String,
            default: 'tv_chart_container'
        },
        datafeedUrl: {
            type: String,
            default: 'https://demo_feed.tradingview.com'
        },
        libraryPath: {
            type: String,
            default: '/charting_library/'
        },
        chartsStorageUrl: {
            type: String,
            default: 'https://saveload.tradingview.com'
        },
        chartsStorageApiVersion: {
            type: String,
            default: '1.1'
        },
        clientId: {
            type: String,
            default: 'tradingview.com'
        },
        userId: {
            type: String,
            default: 'public_user_id'
        },
        fullscreen: {
            type: Boolean,
            default: false
        },
        autosize: {
            type: Boolean,
            default: true
        },
        studiesOverrides: {
            type: Object
        },
        allowSymbolChange: {
            type: Boolean,
            default: true
        },
        disabledFeatures: {
            type: Array,
            default: () => {
                return [];
            }
        },
        entryTime: {
            type: Date,
            default: null
        },
        exitTime: {
            type: Date,
            default: null
        },
        trade: {
            type: Object
        }
    },
    mounted() {
        if(this.disabledFeatures.indexOf('use_localstorage_for_settings') !== -1) {
            this.disabledFeatures.push('use_localstorage_for_settings');
        }

        const chartOptions = {
            symbol: this.symbol,
            datafeed: new DataFeed(this.datafeedUrl),
            interval: this.interval,
            container_id: this.containerId,
            library_path: this.libraryPath,
            locale: 'en',
            disabled_features: ['use_localstorage_for_settings'],
            enabled_features: ['study_templates'],
            charts_storage_url: this.chartsStorageUrl,
            charts_storage_api_version: this.chartsStorageApiVersion,
            client_id: this.clientId,
            user_id: this.userId,
            fullscreen: this.fullscreen,
            autosize: this.autosize,
            studies_overrides: this.studiesOverrides,
            allow_symbol_change: this.allowSymbolChange,
            timezone: 'America/New_York',
        };

        let chart = new widget(chartOptions);
        this.chart = chart;

        let that = this;
        chart.onChartReady(function() {
            chart.chart().dataReady(function() {
                if(typeof that.trade !== 'undefined') {
                    if(typeof that.trade.entryTime === 'string') {
                        that.trade.entryTime = new Date(that.trade.entryTime);
                    }
                    if(typeof that.trade.exitTime !== 'undefined' && typeof that.trade.exitTime === 'string') {
                        that.trade.exitTime = new Date(that.trade.exitTime);
                    }

                    let entryShapeId = null;
                    let entryTime = parseFloat(parseFromTimeZone(format(that.getAdjustedDateTime(that.trade.entryTime, chart.chart().resolution()), 'yyyy-MM-dd HH:mm:ss'), {timeZone: 'America/New_York'}).getTime() / 1000);
                    let exitTime = null
                    if(that.trade.hasOwnProperty("exitTime") && that.trade.exitTime !== "undefined"){
                        exitTime = parseFromTimeZone(format(that.getAdjustedDateTime(that.trade.exitTime, chart.chart().resolution()), 'yyyy-MM-dd HH:mm:ss'), {timeZone: 'America/New_York'}).getTime() / 1000;
                    }
                    //update visible range
                    let curTo = chart.chart().getVisibleRange().to;
                    let resolution = chart.chart().resolution();
                    let exit = exitTime || curTo
                    chart.chart().setVisibleRange({from:that.adjustVisibleRange(entryTime, "-"+resolution), to:that.adjustVisibleRange(exit, resolution)})

                    chart.chart().onVisibleRangeChanged().subscribe(null, function(){
                        //update visible range on resolution change
                        let res = chart.chart().resolution();
                        if(resolution != res){
                            resolution = res;
                            chart.chart().setVisibleRange({from:that.adjustVisibleRange(entryTime, "-"+resolution), to:that.adjustVisibleRange(exit, resolution)}, )
                        }
                    })

                    chart.chart().onDataLoaded().subscribe(null, function() {

                        entryTime = parseFloat(parseFromTimeZone(format(that.getAdjustedDateTime(that.trade.entryTime, chart.chart().resolution()), 'yyyy-MM-dd HH:mm:ss'), {timeZone: 'America/New_York'}).getTime() / 1000);
                        
                        //draw shapes
                        chart.chart().removeAllShapes()
                        if(that.trade.tradeDirection === 'Long') {
                            chart.chart().createShape({time: entryTime, channel: 'low'}, {shape: 'arrow_up', zOrder: 'top'});
                        } else {
                            chart.chart().createShape({time: entryTime, channel: 'high'}, {shape: 'arrow_down', zOrder: 'top'});
                        }

                        let exitShapeId = null;
                        let entryToExitShapeId = null;

                        if(that.trade.exitTime !== 'undefined' && ((((that.trade.exitTime - that.trade.entryTime)/1000)/60)/60)/24 < (364*10) ) {
                            exitTime = parseFromTimeZone(format(that.getAdjustedDateTime(that.trade.exitTime, chart.chart().resolution()), 'yyyy-MM-dd HH:mm:ss'), {timeZone: 'America/New_York'}).getTime() / 1000;
                            if(that.trade.tradeDirection === 'Long') {
                                chart.chart().createShape({time: exitTime, channel: 'high'}, {shape: 'arrow_down', zOrder: 'top'});
                            } else {
                                chart.chart().createShape({time: exitTime, channel: 'low'}, {shape: 'arrow_up', zOrder: 'top'});
                            }

                            if(that.trade.fundReturn < 0) {
                                chart.chart().createMultipointShape([{time: entryTime, price: parseFloat(that.trade.entryPrice)}, {time: exitTime, price: parseFloat(that.trade.exitPrice)}], {shape: 'trend_line', overrides: {linecolor: '#EF5350'}});
                            } else {
                                chart.chart().createMultipointShape([{time: entryTime, price: parseFloat(that.trade.entryPrice)}, {time: exitTime, price: parseFloat(that.trade.exitPrice)}], {shape: 'trend_line', overrides: {linecolor: '#26A69A'}});
                            }
                        }

                    });
                }
            });

        });
    },
    beforeDestroy() {
        if (this.chart !== null) {
            this.chart.remove();
            this.chart = null;
        }
    },
    methods: {
        getAdjustedDateTime(dateTime, resolution) {
            if(isNaN(Number(resolution))) {
                let value = parseInt(resolution.substring(0, resolution.length - 1));
                let unit = resolution.substring(1, resolution.length).toUpperCase();

                if(unit === 'H') {
                    return addHours(dateTime, value);
                }

                if(unit === 'D') {
                    return addDays(dateTime, value);
                }

                if(unit === 'W') {
                    return addWeeks(dateTime, value);
                }

                if(unit === 'M') {
                    return addMonths(dateTime, value);
                }
            } else {
                return addMinutes(dateTime, parseInt(resolution));
            }
        },
        adjustVisibleRange(time, resolution) {
            let amount = 3;
            if(isNaN(resolution)) {
                let value = parseInt(resolution.substring(0, resolution.length - 1));
                let unit = resolution.substring(1, resolution.length).toUpperCase();
                value = value * amount

                if(unit === 'H') {
                    return addHours(time, value);
                }

                if(unit === 'D') {
                    return addDays(time, value);
                }

                if(unit === 'W') {
                    return addWeeks(time, value);
                }

                if(unit === 'M') {
                    return addMonths(time, value);
                }
            } else {
                return time + ((resolution * 100) * amount);
            }
        }
    }
}
</script>